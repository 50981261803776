import React, { useEffect, useState } from "react";
import G1Head from "../../images/g1-head.png";
import G2Head from "../../images/g2-head.png";
import G3Head from "../../images/g3-head.png";
import G4Head from "../../images/g4-head.png";
import G1Split from "../../images/g1-split.png";
import G2Split from "../../images/g2-split.png";
import G3Split from "../../images/g3-split.png";
import G4Split from "../../images/g4-split.png";


const Product = () => {
  const [selectedFloor, setSelectedFloor] = useState("G+1");
  const [selectedType, setSelectedType] = useState("Head");
  const [selectedImage, setSelectedImage] = useState();
  const [elevatorData] = useState([
    {
      title: "Cylinder Diameter",
      value: "960 mm",
    },
    {
      title: "Clear Cabin Diameter",
      value: "850 mm",
    },
    {
      title: "Clear Cabin Height",
      value: "2010 mm",
    },
    {
      title: "Door Opening Width",
      value: "525 mm",
    },
    {
      title: "Headroom Required",
      value: "Min 2600 mm",
    },
    {
      title: "Speed",
      value: "0.20 m/s",
    },
    {
      title: "Capacity",
      value: "220 KGs",
    },
    {
      title: "Power Supply",
      value: "Single Phase/32 amps/220 V",
    },
    {
      title: "Additional Features",
      value: "Automatic LED lighting and cabin fan",
    },
  ]);

  const [imageData] = useState([
    {
      value: "G+1",
      srcHead: G1Head, 
      srcSplit: G1Split
    },
    {
      value: "G+2",
      srcHead: G2Head, 
      srcSplit: G2Split
    },
    {
      value: "G+3",
      srcHead: G3Head, 
      srcSplit: G3Split
    },
    {
      value: "G+4",
      srcHead: G4Head, 
      srcSplit: G4Split
    },
  ]);

  const floorOptions = [
    {
      value: "G+1",
      label: "Ground floor and one additional floor",
    },
    {
      value: "G+2",
      label: "Ground floor and two additional floors",
    },
    {
      value: "G+3",
      label: "Ground floor and three additional floors",
    },
    {
      value: "G+4",
      label: "Ground floor and four additional floors",
    },
  ];

  const elevatorsType = [
    {
      type: "Head",
      description: "Located on top of the vertical cylinder.",
    },
    {
      type: "Split",
      description: "Located in a split box that can be installed 10m away.",
    },
  ];

  useEffect(() => {
    if (selectedFloor) {
      setSelectedImage(
        imageData.filter((img) => img.value === selectedFloor)[0][`src${selectedType}`]
      );
    }
  }, [selectedFloor, imageData, selectedType]);

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <section className="product" id="prodcut">
      <div className="product-wrapper">
        <h3 className="font-bold text-heading-text-lg font-montserrat text-[4.4rem] mb-[4.8rem]">
          Product
        </h3>

        <div className="product-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[3.6rem]">
          <ul className="product-features">
            {elevatorData.map((data) => (
              <li
                className="product__feature font-opensans text-[1.6rem] leading-loose font-normal text-body-text"
                key={data.title}
              >
                <span className="font-semibold mb-[0.8rem] inline-block">
                  {data.title}:&nbsp;
                </span>
                <span>{data.value}</span>
              </li>
            ))}
          </ul>

          <div className="product-image bg-background rounded-[12px] flex items-center justify-center shadow-md">
            <img
              src={selectedImage}
              alt="Vaccum elevator by floor type"
              className="object-contain h-[44rem] py-[3.6rem]"
            />
          </div>

          <div className="product-radio flex flex-col items-center justify-center gap-[2.4rem] col-span-1 md:col-span-2 lg:col-span-1">
            {/* Elevator Type Option */}
            <div className="flex w-full gap-[1.6rem]">
              {elevatorsType.map((elevator) => (
                <label
                  key={elevator.type}
                  className="rounded-[12px] p-[1.6rem] shadow-md w-full"
                >
                  <input
                    type="radio"
                    name="type"
                    value={elevator.type}
                    className=" mb-[1.6rem] mr-[0.8rem] cursor-pointer"
                    checked={selectedType === elevator.type}
                    onChange={handleTypeChange}
                  />
                  <span className="text-[1.6rem] font-opensans font-semibold text-body-text">
                    {elevator.type}&nbsp;
                    <p className="font-normal">{elevator.description}</p>
                  </span>
                </label>
              ))}
            </div>
            {/* Floor options */}
            {floorOptions.map((floor) => (
              <label
                key={floor.label}
                className="rounded-[12px] p-[1.6rem] shadow-md w-full"
              >
                <input
                  type="radio"
                  name="floors"
                  value={floor.value}
                  className=" mb-[1.6rem] mr-[0.8rem] cursor-pointer"
                  checked={selectedFloor === floor.value}
                  onChange={handleFloorChange}
                />
                <span className="text-[1.6rem] font-opensans font-semibold text-body-text">
                  {floor.value}&nbsp;
                  <p className="font-normal">{floor.label}</p>
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Product;
