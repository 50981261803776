import {
  Contact,
  Customization,
  Features,
  Footer,
  Gallery,
  Header,
  Hero,
  Product,
} from "../components";

const RootLayout = () => {
  return (
    <div className="app relative">
      <Header />

      <main>
        <Hero />
        <Features />
        <Product />
        <Customization />
        <Gallery />
        <Contact />
      </main>

      <Footer />
    </div>
  );
};

export default RootLayout;
